.team .member .member-img:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: url(./img/team-shape.svg) no-repeat center bottom;
  background-size: contain;
  z-index: 1;
}
